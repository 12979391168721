import $ from 'jquery';
import ScrollMagic from 'ScrollMagic';
import {TweenMax, TimelineMax, Power0, Back} from 'gsap';
import {ScrollMagicPluginGsap} from 'scrollmagic-plugin-gsap';
import debounce from 'lodash.debounce';

$(document).ready(() => {
    if ($('body').hasClass('home')) {
        ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
        let controller;

        fixFirstSlideSizes();

        if (window.innerWidth >= 992) {
            controller = new ScrollMagic.Controller();
            animateHomepage(controller);
        }

        $('.karl').on('load', function () {
            fixFirstSlideSizes();

            if (controller) {
                controller.destroy(true);
                controller = new ScrollMagic.Controller();
                animateHomepage(controller);
            }
        });

        $(window).on('resize', debounce(function () {
            fixFirstSlideSizes();

            if (window.innerWidth >= 992) {
                if (controller) {
                    controller.destroy(true);
                }
                controller = new ScrollMagic.Controller();
                animateHomepage(controller);
            }
        }, 300))

        let $eliWrapper = $('.eli-wrapper');
        if ($eliWrapper.length > 0) {
            let eliObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        $(entry.target).addClass('animate');
                    }
                });
            }, {
                threshold: 0.66,
            });
            eliObserver.observe($eliWrapper[0]);
        }
    }

    $('form.validate').attr('novalidate', 'true');

    // more: https://css-tricks.com/form-validation-part-2-constraint-validation-api-javascript/
    document.addEventListener('blur', function (e) {
        if (!$(e.target.form).hasClass('validate')) return;

        let error = hasError(e.target);

        if (error) {
            showError(e.target, error);
            return;
        }

        removeError(e.target);
    }, true);

    $('#signup').on('submit', function (e) {
        e.preventDefault();

        let form = $(this);

        if (form.hasClass('validate')) {
            // Get all of the form elements
            let fields = e.target.elements;

            let error, hasErrors;

            for (let i = 0; i < fields.length; i++) {
                error = hasError(fields[i]);
                if (error) {
                    showError(fields[i], error);
                    if (!hasErrors) {
                        hasErrors = fields[i];
                    }
                }
            }

            // If there are errors, don't submit form and focus on first element with error
            if (hasErrors) {
                hasErrors.focus();
                return;
            }
        }

        let $btn = $('.js-submit');
        let oldValue = $btn.html();
        $btn.attr('disabled', '').html('Pošiljam');

        $.ajax({
            type: "POST",
            url: 'send-email.php',
            success: function (response) {
                let data = JSON.parse(response);

                if (data.error) {
                    $('.response')
                        .removeClass('error success')
                        .addClass('error')
                        .html(data.error);
                } else {
                    $('.response')
                        .removeClass('error success')
                        .addClass('success')
                        .html(data.success);

                    form.each(function () {
                        this.reset();
                    });
                }

                $btn.removeAttr('disabled').html(oldValue);
            },
            data: form.serialize(),
        });
    });

    function fixFirstSlideSizes() {
        let $karl = $('.karl');
        let $bubble = $('.karl-bubble');
        let $frame = $('.frame');
        let $ipad = $('.ipad');

        let karlWidth = $karl.outerWidth();
        let karlHeight = $karl.outerHeight();
        let frameWidth = karlWidth + 30;
        let ipadWidth = karlWidth + 20;

        $frame.css('width', `${frameWidth}px`);
        $ipad.css({
            'width': `${ipadWidth}px`,
            'height': `${karlHeight + 20}px`,
            'clip-path': `inset(0 ${ipadWidth}px 0 0)`
        });

        let karlOffset = $karl.offset();
        $bubble.css({
            'top': `${karlOffset.top + karlHeight * 0.1}px`,
            'left': `${karlOffset.left + karlWidth * 0.7}px`
        });
    }

    function animateHomepage(controller) {
        let $wallpaper = $('.wallpaper');
        let $frame = $('.frame');
        let $ipad = $('.ipad');

        let wallpaperWidth = $wallpaper.outerWidth();
        let frameWidth = $frame.outerWidth();
        let ipadWidth = $ipad.outerWidth();

        let wallpaperOffset = $wallpaper.offset();

        let frameOffset = $frame.offset();
        let frameLeftOffset = frameOffset.left - wallpaperOffset.left;
        let frameRightOffset = wallpaperWidth - frameWidth - frameLeftOffset;

        let ipadOffset = $ipad.offset();
        let ipadLeftOffset = ipadOffset.left - wallpaperOffset.left;
        let ipadRightOffset = wallpaperWidth - ipadWidth - ipadLeftOffset;

        let fromWallpaperToFrame = frameLeftOffset / wallpaperWidth;
        let fromFrameToWallpaper = frameRightOffset / wallpaperWidth;

        let fromWallpaperToIpad = ipadLeftOffset / wallpaperWidth;
        let fromIpadToWallpaper = ipadRightOffset / wallpaperWidth;

        let frameDelay = fromWallpaperToFrame;
        let frameDuration = 1 - fromWallpaperToFrame - fromFrameToWallpaper;

        let ipadDelay = fromWallpaperToIpad;
        let ipadDuration = 1 - fromWallpaperToIpad - fromIpadToWallpaper;

        let wipeAnimation = new TimelineMax()
            .add(
                TweenMax.to(
                    '.wallpaper', // element
                    1, // duration
                    {x: '100%', ease: Power0.easeNone}), // transformation
                0, // delay
            )
            .add(
                TweenMax.to(
                    '.frame', // element
                    frameDuration, // duration
                    {clipPath: `inset(0 0 0 ${frameWidth}px)`, ease: Power0.easeNone}), // transformation
                frameDelay, // delay
            )
            .add(
                TweenMax.to(
                    '.ipad',
                    ipadDuration,
                    {
                        clipPath: 'inset(0 0px 0 0)',
                        ease: Power0.easeNone,
                        onComplete: () => {
                            $('.karl-bubble').addClass('animate');
                        },
                        onReverseComplete: () => {
                            $('.karl-bubble').removeClass('animate');
                        },
                    },
                ),
                ipadDelay,
            )

        new ScrollMagic.Scene({
            triggerElement: '.js-slide-1',
            triggerHook: 'onLeave',
            duration: '100%',
        })
            .setPin('.js-slide-1')
            .setTween(wipeAnimation)
            .addTo(controller);

        new ScrollMagic.Scene({
            triggerElement: '.js-slide-2',
            triggerHook: 'onEnter',
        })
            .setPin('.js-slide-1')
            .addTo(controller);

        let blurAnimation = new TimelineMax()
            .add(TweenMax.fromTo('.text-1', 1, {opacity: 0, y: '-50%'}, {y: 0, opacity: 1}), 0)
            .add(TweenMax.to('.text-1', 3, {filter: 'blur(8px)', opacity: 0, scale: 1.2}), 3)
            .add(TweenMax.fromTo('.text-2', 3, {opacity: 0}, {opacity: 1}), 4)

        new ScrollMagic.Scene({
            triggerElement: '.js-slide-2',
            triggerHook: 'onLeave',
            duration: '100%',
        })
            .setPin('.js-slide-2', {pushFollowers: true})
            .setTween(blurAnimation)
            .addTo(controller);

        let slide3TextAnimation = new TimelineMax()
            .add(TweenMax.fromTo('.js-slide-3-text-1', 1, {opacity: 0}, {opacity: 1}), 0)
            .add(TweenMax.to('.js-slide-3-text-1', 1, {opacity: 0}), 1)
            .add(TweenMax.fromTo('.js-slide-3-text-2', 1, {opacity: 0}, {opacity: 1}), 2)
            .add(TweenMax.to('.js-slide-3-text-2', 1, {opacity: 0}), 3)
            .add(TweenMax.fromTo('.js-slide-3-text-3', 1, {opacity: 0}, {opacity: 1}), 4)

        new ScrollMagic.Scene({
            triggerElement: '.js-slide-3',
            triggerHook: 'onLeave',
            duration: '100%',
        })
            .setTween(slide3TextAnimation)
            .setPin('.js-slide-3', {pushFollowers: false})
            .addTo(controller);
    }

    // Validate the field
    let hasError = function (field) {

        // Don't validate submits, buttons, file and reset inputs, and disabled fields
        if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return;

        // Get validity
        let validity = field.validity;

        // If valid, return null
        if (validity.valid) return;

        // If field is required and empty
        if (validity.valueMissing) return 'To polje je obvezno.';

        // If not the right type
        if (validity.typeMismatch) {
            // Email
            if (field.type === 'email') return 'Prosimo vnesite veljaven email naslov.';

            // URL
            if (field.type === 'url') return 'Prosimo vnesite veljaven URL naslov.';
        }

        // If too short
        if (validity.tooShort) return 'Polje mora vsebovati vsaj ' + field.getAttribute('minLength') + ' znakov ali več, trenuten vnos pa ima ' + field.value.length + ' znakov.';

        // If too long
        if (validity.tooLong) return 'Polje ne sme vsebovati več kot ' + field.getAttribute('maxLength') + ' znakov, treuten vnos pa ima ' + field.value.length + ' znakov.';

        // If number input isn't a number
        if (validity.badInput) return 'Prosimo vnesite veljavno število.';

        // If a number value doesn't match the step interval
        if (validity.stepMismatch) return 'Prosimo vnesite veljavno vrednost.';

        // If a number field is over the max
        if (validity.rangeOverflow) return 'Prosimo popravite vrednost, da ne bo večja od ' + field.getAttribute('max') + '.';

        // If a number field is below the min
        if (validity.rangeUnderflow) return 'Prosimo popravite vrednost, da ne bo manjša od ' + field.getAttribute('min') + '.';

        // If pattern doesn't match
        if (validity.patternMismatch) {
            // If pattern info is included, return custom error
            if (field.hasAttribute('title')) return field.getAttribute('title');

            // Otherwise, generic error
            return 'Polje ni v pravilnem formatu.';
        }

        // If all else fails, return a generic catchall error
        return 'Vrednost, ki ste je vnesli v to polje, je neveljavna.';

    };

    // Show the error message
    let showError = function (field, error) {
        let $field = $(field);

        $field.addClass('error');

        let id = $field.attr('id') || $field.attr('name');
        if (!id) return;

        // Check if error message field already exists
        // If not, create one
        let message = $('.error-message#error-for-' + id);
        if (message.length <= 0) {
            message = $(`<div class="error-message" id ="error-for-${id}"></div>`);
            message.insertBefore(field.nextSibling);
        }

        // Add ARIA role to the field
        field.setAttribute('aria-describedby', 'error-for-' + id);

        // Update error message
        $(message).html(error);

        // Show error message
        $(message).show();
    };

    // Remove the error message
    let removeError = function (field) {
        $(field).removeClass('error');
        field.removeAttribute('aria-describedby');

        let id = $(field).attr('id') || $(field).attr('name');
        if (!id) return;

        // Check if an error message is in the DOM
        let message = $('.error-message#error-for-' + id + '');
        if (message.length <= 0) return;

        // If so, hide it
        $(message).html();
        $(message).hide();
    };
});
